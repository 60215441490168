<template>
  <div class="v-card" :class="[
    elevationClass,
  ]">
    <div class="v-card-content">
      <slot/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import useElevationClass, { elevationProps } from '@/composables/use-elevation-class/';

export default defineComponent({
  name: 'VCard',
  components: {
    //
  },
  props: {
    ...elevationProps,
  },
  setup(props) {
    const elevationClass = computed(() => {
      if (props.elevation) {
        return useElevationClass(props).value;
      }
      return useElevationClass({ elevation: 6 }).value;
    });

    return { elevationClass };
  },
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @include elevation;

  .v-card {
    padding: 16px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
</style>
