
import { defineComponent, computed, PropType } from 'vue';
import useElevationClass, { elevationProps } from '@/composables/use-elevation-class/';

export default defineComponent({
  name: 'VButton',
  components: {
    //
  },
  props: {
    fab: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    // bottom, top, right, left only to be used in combination with "fab"
    // as they only work on absolute
    bottom: {
      type: Boolean,
      required: false,
    },
    top: {
      type: Boolean,
      required: false,
    },
    right: {
      type: Boolean,
      required: false,
    },
    left: {
      type: Boolean,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: false,
    },
    to: {
      type: Object as PropType<string | Location>,
      required: false,
    },
    ...elevationProps,
  },
  setup(props) {
    const elevationClass = computed(() => {
      if (props.elevation) {
        return useElevationClass(props).value;
      }
      if (props.fab) {
        return useElevationClass({ elevation: 12 }).value;
      }
      return null;
    });

    // eslint-disable-next-line arrow-body-style
    const componentType = computed(() => {
      return props.to ? 'router-link' : 'div';
    });

    return { elevationClass, componentType };
  },
});
