
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'VDrawer',
  components: {
    //
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const transformation = computed(() => {
      if (!props.modelValue) return 'transform: translateX(100%);';
      return 'transform: translateX(0);';
    });

    const opacity = computed(() => {
      if (!props.modelValue) return 'opacity: 0;';
      return 'opacity: 0.75;';
    });

    const closeDrawer = () => {
      emit('update:modelValue', !props.modelValue);
    };

    return { transformation, opacity, closeDrawer };
  },
});
