<template>
  <div id="nav" ref="navigation" >
    <router-link to="/">
      <img
        alt="Abendgestaltungen Logo"
        src="/img/icons/android-chrome-192x192.png"
        width="40"
        height="40"
      />
    </router-link>
    
    <div class="nav-links">
      <router-link :to="{ name: 'Start' }">Start</router-link>
      <router-link :to="{ name: 'Alle Abendgestaltungen' }">Alle Abendgestaltungen</router-link>
      <router-link :to="{ name: 'Über das Projekt' }">Über das Projekt</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, ref, onMounted, onBeforeUnmount,
} from 'vue';
import getSize from '@/composables/get-size';

export default defineComponent({
  name: 'Navigation',
  props: {
    //
  },
  
  setup(props, { emit }) {
    const navigation = ref<HTMLElement>();

    onMounted(() => {
      emit('mounted', getSize(navigation));
    });

    onBeforeUnmount(() => {
      emit('unmount', getSize(navigation));
    });

    return { navigation };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  #nav {
    @include flex-row;
    height: 40px;

    padding: 10px 40px;
    background-color: var(--color-main);

    .nav-links {
      a {
        @include nav-link;
      }
    }
  }
</style>
