
import {
  defineComponent, ref, onMounted, onBeforeUnmount,
} from 'vue';
import getSize from '@/composables/get-size';

export default defineComponent({
  name: 'Footer',
  props: {
    //
  },

  setup(props, { emit }) {
    const footer = ref<HTMLElement>();

    onMounted(() => {
      emit('mounted', getSize(footer));
    });

    onBeforeUnmount(() => {
      emit('unmount', getSize(footer));
    });

    return { footer };
  },
});
