
import {
  defineComponent, ref, onMounted, onBeforeUnmount,
} from 'vue';
import getSize from '@/composables/get-size';

export default defineComponent({
  name: 'Navigation',
  props: {
    //
  },
  
  setup(props, { emit }) {
    const navigation = ref<HTMLElement>();

    onMounted(() => {
      emit('mounted', getSize(navigation));
    });

    onBeforeUnmount(() => {
      emit('unmount', getSize(navigation));
    });

    return { navigation };
  },
});
