<template>
  <div>{{ title }}</div>
  <div v-if="selection.length !== 0">
      <span
        v-for="(selectedValue, index) in selection"
        :key="index"
        class="selected-value"
      >
        <span>{{ selectedValue }}</span>
        <span class="remove-selection" @click="removeSelection(selectedValue)">x</span>
      </span>

  </div>
  <!-- TODO: restrict to List-Values. Right now you can also enter custom ones  -->
  <!-- TODO: filter list once an input is selected -->
  <input :list="id" @change="updateSelection" v-model="input" />
  <datalist :id="id">
    <option
      v-for="option in options"
      :key="option.key || option.id || option"
    >
      {{ option.value || option.title || option }}
    </option>
  </datalist>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { getUniqueId } from '@/utils/helper';

interface VAutocompleteOptions {
  key: string | number,
  value: string | number,
}

export default defineComponent({
  name: 'VAutocomplete',
  components: {
    //
  },
  props: {
    options: {
      type: Array as PropType<Array<VAutocompleteOptions> | Array<number> | Array<string>>,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
  setup() {
    const id = getUniqueId('autocomplete-');
    const selection = ref<Array<string | number | VAutocompleteOptions>>([]);
    const input = ref<string>('');

    const updateSelection = (event: { target: HTMLInputElement }) => {
      if (event.target.value) {
        input.value = '';
        if (!selection.value.includes(event.target.value)) {
          selection.value.push(event.target.value);
        }
      }
    };

    const removeSelection = (selectionToRemove: string | number | VAutocompleteOptions) => {
      selection.value = selection.value.filter((value) => value !== selectionToRemove);
    };

    return {
      id, selection, updateSelection, input, removeSelection,
    };
    // const optionsC = computed((props) => {
    //   // TODO Add Reset Option if set via prop
    //   const computedOptions: Array<VAutocompleteOptions> = [];
    //   props.options.forEach((option: number | string | VAutocompleteOptions) => {
    //     if (props.addReset) {
    //       computedOptions.push({ key: '', value: '- - -' });
    //     }
    //     if (typeof option === 'string' || typeof option === 'number') {
    //       computedOptions.push({ key: option, value: toString });
    //     } else {
    //       computedOptions.push({ key: option.key, value: option.value });
    //     }
    //   });
    //
    //   return computedOptions;
    // });
    //
    // return { optionsC };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .selected-value {
    background-color: var(--color-main);
    color: var(--color-text--light);
    padding: 5px 0 5px 10px;
    margin-right: 5px;
    border-radius: 20px;
  }

  .remove-selection {
    @extend .selected-value;
    background-color: var(--color-main--darken-10);
    cursor: pointer;
    padding-right: 10px;
    margin-left: 5px;
    margin-right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }


</style>
