
import { defineComponent } from 'vue';
import { mdiHome } from '@mdi/js';
import useSizeClass, { sizeProps } from '@/composables/use-size-class/';


export default defineComponent({
  name: 'VIcon',
  components: {
    //
  },
  props: {
    icon: {
      type: String,
      required: true,
      default: mdiHome,
    },
    ...sizeProps,
  },
  setup(props) {
    const sizeClass = useSizeClass(props);
    return { sizeClass };
  },
});
