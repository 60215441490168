<template>
  <div class="v-icon" :class="sizeClass">
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path :d="icon" x-small />
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mdiHome } from '@mdi/js';
import useSizeClass, { sizeProps } from '@/composables/use-size-class/';


export default defineComponent({
  name: 'VIcon',
  components: {
    //
  },
  props: {
    icon: {
      type: String,
      required: true,
      default: mdiHome,
    },
    ...sizeProps,
  },
  setup(props) {
    const sizeClass = useSizeClass(props);
    return { sizeClass };
  },
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .v-icon {
    height: var(--size-font);
    width: var(--size-font);
    // display: block;
    // margin: auto;

    &.x-large {
      height: var(--size-font--x-large);
      width: var(--size-font--x-large);
    }

    path {
      fill: var(--color-text--light);
    }
  }

</style>
