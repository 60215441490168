<template>
  <div class="activity-card">
    <v-card>
      <h3>{{ activity.title }}</h3>
      <div class="description">
        <h4>Beschreibung</h4>
        <v-markdown :modelValue="activity.description"/>
      </div>
      <div class="attributes">
        <div>Preis: {{ activity.price }}€</div>
        <div>Schwierigkeit: {{ activity.easiness }}</div>
        <div>Kategorien:
          <span v-for="(cagetory, index) in categories" :key="index">
            {{ cagetory }},
          </span>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import { ActivityEntry } from '@/api/types';
import { vCard, vMarkdown } from '@/components/base/';

export default defineComponent({
  name: 'ActivityCard',
  components: {
    vCard,
    vMarkdown,
  },
  props: {
    activity: {
      type: Object as PropType<ActivityEntry>,
      required: true,
    },
  },
  setup(props) {
    // const categories = ref<Array<string>>([]);
    const categories = computed(() => {
      const catList: Array<string> = [];

      const cat = props.activity?.categories ?? [];
      cat.forEach((element) => {
        catList.push(element.category_id.title ?? element.category_id);
      });
      return catList;
    });


    return { categories };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .activity-card {

  }
</style>
