import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/**
 * Listens to a router action and sets the document title
 * @type {[type]}
 */
router.beforeEach((to, from, next) => {
  // use given route meta title or default value
  document.title = to.meta.title || 'Abendgestaltungen';

  if (
    to.matched[0].components.default.name === 'Page'
    && !to.meta.apiSlug
  ) {
    next('/404');
  }

  // continue
  next();
});

export default router;
