<template>
  <div id="footer" ref="footer">
    <div class="nav-links">
      <router-link :to="{ name: 'Impressum' }">Impressum</router-link>
      <router-link :to="{ name: 'Datenschutz' }">Datenschutz</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, ref, onMounted, onBeforeUnmount,
} from 'vue';
import getSize from '@/composables/get-size';

export default defineComponent({
  name: 'Footer',
  props: {
    //
  },

  setup(props, { emit }) {
    const footer = ref<HTMLElement>();

    onMounted(() => {
      emit('mounted', getSize(footer));
    });

    onBeforeUnmount(() => {
      emit('unmount', getSize(footer));
    });

    return { footer };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  #footer {
    box-sizing: border-box;
    width: 100%;

    @include flex-row;

    padding: 10px 40px;
    background-color: var(--color-background-dark);
    .nav-links {
      a {
        @include nav-link;
      }
    }
  }
</style>
