
import { defineComponent, PropType, computed } from 'vue';
import { ActivityEntry } from '@/api/types';
import { vCard, vMarkdown } from '@/components/base/';

export default defineComponent({
  name: 'ActivityCard',
  components: {
    vCard,
    vMarkdown,
  },
  props: {
    activity: {
      type: Object as PropType<ActivityEntry>,
      required: true,
    },
  },
  setup(props) {
    // const categories = ref<Array<string>>([]);
    const categories = computed(() => {
      const catList: Array<string> = [];

      const cat = props.activity?.categories ?? [];
      cat.forEach((element) => {
        catList.push(element.category_id.title ?? element.category_id);
      });
      return catList;
    });


    return { categories };
  },
});
