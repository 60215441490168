<template>
  <div>
    AlleAbendgestaltungen
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AlleAbendgestaltungen',
  components: {
    //
  },
  props: {
    //
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
