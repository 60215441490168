<template>
  <div class="page">
    <h1>{{ apiPage.title }}</h1>
    <v-markdown :modelValue="apiPage.content" />
  </div>
</template>


<script lang="ts">
import {
  defineComponent, computed, ref, onMounted, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import api from '@/api';
import { vMarkdown } from '@/components/base/';

export default defineComponent({
  name: 'ApiPage',
  components: {
    vMarkdown,
  },

  setup() {
    const router = useRouter();
    const apiSlug = computed(() => router.currentRoute.value.meta.apiSlug);
    const apiPage = ref({});

    const fetchContent = async () => {
      await api('/items/page', {
        params: {
          filter: {
            slug: {
              _eq: apiSlug.value,
            },
          },
        },
      }).then((response) => {
        apiPage.value = response?.data?.data[0];
      });
    };

    onMounted(() => {
      fetchContent();
    });

    watch(apiSlug, fetchContent);
    
    return { apiSlug, apiPage };
  },
});
</script>

<style lang="scss" scoped>

</style>
