
import { defineComponent, ref, computed } from 'vue';
import Navigation from '@/components/core/Navigation.vue';
import Footer from '@/components/core/Footer.vue';
import type { SizeType } from '@/composables/get-size';

type ResizeOperator = 'substract' | 'add';

export default defineComponent({
  name: 'App',
  components: {
    Navigation,
    Footer,
  },

  setup() {
    const mainContentHeight = ref(window.innerHeight - 80); // -80px as margin-Y
    const mainContent = ref<HTMLElement>();

    // TODO: also watch window resize
    const updateMainHeight = ((size: SizeType, operator: ResizeOperator) => {
      if (operator === 'substract') {
        mainContentHeight.value -= size.height;
      } else {
        mainContentHeight.value += size.height;
      }
    });

    const cssVars = computed(() => {
      const vars = {
        '--main-content-height': `${mainContentHeight.value}px`,
      };
      return vars;
    });

    return {
      updateMainHeight, mainContentHeight, mainContent, cssVars,
    };
  },

});
