// import axios from 'axios';
import { ActivityEntry, CategoryItem } from './types';
import api from './index';

type queryParams = {
  fields?: string,
  offset?: number,
  limit?: number,
  sort?: string,
}

export async function fetchActivities(params: queryParams): Promise<ActivityEntry[]> {
  return api('/items/activity', {
    params: {
      ...params,
    },
  })
    .then((response) => {
      if (response.data.data) {
        return response.data.data;
      }
      return [];
    })
    .catch((error) => {
      console.log(error);
    });
}

/* eslint-disable-next-line */
// export async function fetchRandomActivity(totalActivities: number): Promise<ActivityEntry> {
//   // random number between 0 and (totalActivities -1)
//   const randomOffsetNumer = Math.floor(Math.random() * totalActivities);
//   const params = {
//     offset: randomOffsetNumer,
//     fields: '*,categories.category_id.*',
//     limit: 1,
//   };

//   const activity = await fetchActivities(params);
//   return activity[0];
// }


export async function fetchNumberOfActivities(): Promise<number> {
  return api('/items/activity', {
    params: {
      meta: 'filter_count',
      limit: 0,
      filter: {
        status: {
          _eq: 'published',
        },
      },
    },
  })
    .then((response) => {
      if (response.data.meta && response.data.meta.filter_count) {
        return response.data.meta.filter_count;
      }
      return 0;
    })
    .catch((error) => {
      console.log(error);
    });
}


export async function fetchCategories(): Promise<null | CategoryItem[]> {
  return api('/items/category', {
    params: {
      sort: 'title',
    },
  })
    .then((response) => {
      if (response.data.data && response.data.data[0]) {
        return response.data.data;
      }
      return null;
    })
    .catch((error) => {
      console.log(error);
    });
}
