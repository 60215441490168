<template>
  <Navigation
    @mounted="updateMainHeight($event, 'substract')"
    @unmount="updateMainHeight($event, 'add')"
  />
  <div class="main-content" ref="mainContent" :style='cssVars'>
    <router-view/>
  </div>
  <Footer
    @mounted="updateMainHeight($event, 'substract')"
    @unmount="updateMainHeight($event, 'add')"
  />
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import Navigation from '@/components/core/Navigation.vue';
import Footer from '@/components/core/Footer.vue';
import type { SizeType } from '@/composables/get-size';

type ResizeOperator = 'substract' | 'add';

export default defineComponent({
  name: 'App',
  components: {
    Navigation,
    Footer,
  },

  setup() {
    const mainContentHeight = ref(window.innerHeight - 80); // -80px as margin-Y
    const mainContent = ref<HTMLElement>();

    // TODO: also watch window resize
    const updateMainHeight = ((size: SizeType, operator: ResizeOperator) => {
      if (operator === 'substract') {
        mainContentHeight.value -= size.height;
      } else {
        mainContentHeight.value += size.height;
      }
    });

    const cssVars = computed(() => {
      const vars = {
        '--main-content-height': `${mainContentHeight.value}px`,
      };
      return vars;
    });

    return {
      updateMainHeight, mainContentHeight, mainContent, cssVars,
    };
  },

});
</script>

<style lang="scss">

  html {
    position: relative;
    overflow-x: hidden;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-text);
    word-break: break-word;
  }
</style>

<style lang="scss" scoped>
  .main-content {
    --main-content-height: 100vh;
    min-height: var(--main-content-height);
    display: flex;
    margin: 40px 40px;  // calc margin-y in min-height (in mainContentHeight)
  }
</style>
