<template>
  <h3>Filter</h3>
  <template v-if="categories">
    <v-autocomplete title="Kategorien" :options="categories" />
  </template>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { vAutocomplete } from '@/components/base/';
import { fetchCategories } from '@/api/backend-api';
import { CategoryItem } from '@/api/types';

export default defineComponent({
  name: 'ActivityFilter',
  components: {
    vAutocomplete,
  },
  props: {
    //
  },

  setup() {
    const categories = ref<null | Array<string | number | CategoryItem>>([]);
    const neededEquipment = ref<Array<string | number | CategoryItem>>([]);

    const getCagetories = async () => {
      categories.value = await fetchCategories();
      // const fetchedCategories = await fetchCategories();

      // if (fetchedCategories) {
      //   const keys = Object.keys(fetchedCategories);
      //   keys.forEach((key: number) => {
      //     console.log(fetchedCategories[key]);
      //   });
      // }
    };

    const getNeededEquipment = async () => {
      neededEquipment.value = ['TestEquipment 1', 'TestEquipment 2'];
    };

    onMounted(() => {
      getCagetories();
      getNeededEquipment();
    });


    return {
      categories,
      neededEquipment,
    };
  },

});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
