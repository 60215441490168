
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Error404',

  setup() {
    //
  },
});
