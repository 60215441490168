<template>
  <div class="home">

    <h2>Zufällige Aktivität</h2>
    <!--  TODO: set height before loaded  -->
    <ActivityCard v-if="randomActivity" :activity='randomActivity' />

    <v-button fab bottom right fixed @click.stop="toggleDrawer" >
      <v-icon :icon="icons.settings" x-large />
    </v-button>

    <v-drawer v-model="drawer" >
      <ActivityFilters />
    </v-drawer>

    <v-button @click="getSingleActivity" outlined >Neue Aktivität laden</v-button>

    <h2 ref="allActivitiesList">
      Alle Aktivitäten
    </h2>
    <template v-if="activities">
      <ActivityCard
        v-for="(activity, index) in activities"
        :key="index"
        :activity='activity'
      />
    </template>
    <v-pagination
      :numberOfPages="numberOfPages"
      :currentPage="currentPage"
      @click="scrollToListStart"
    />

  </div>
</template>

<script lang="ts">
import {
  defineComponent, ref, onMounted, computed, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { fetchNumberOfActivities, fetchActivities } from '@/api/backend-api';
import { ActivityEntry } from '@/api/types';
import ActivityCard from '@/components/displays/ActivityCard.vue';
import ActivityFilters from '@/components/displays/ActivityFilters.vue';
import {
  vButton, vDrawer, vIcon, vPagination,
} from '@/components/base/';

import { mdiTuneVertical } from '@mdi/js';

export default defineComponent({
  name: 'Home',
  components: {
    ActivityCard,
    ActivityFilters,
    vButton,
    vIcon,
    vDrawer,
    vPagination,
  },

  setup() {
    const route = useRoute();

    const totalActivities = ref<number>(0);
    const randomActivity = ref<ActivityEntry | null>(null);
    const drawer = ref(false);
    const activitiesPerPage = 10;
    const sortBy = 'date_updated';
    const activities = ref<ActivityEntry[] | null>(null);
    const allActivitiesList = ref<null | HTMLElement>(null);


    const toggleDrawer = ():void => {
      drawer.value = !drawer.value;
    };

    const numberOfPages = computed(() => {
      if (totalActivities.value) {
        return Math.ceil(totalActivities.value / activitiesPerPage);
      }
      return 1;
    });

    const currentPage = computed(() => {
      if (route.params.pageId) {
        return Number.parseInt((route.params.pageId as string), 10);
      }
      return 1;
    });

    const getNumberOfActivities = async () => {
      totalActivities.value = await fetchNumberOfActivities();
    };

    const getSingleActivity = async () => {
      const randomOffsetNumer = Math.floor(Math.random() * totalActivities.value);
      const params = {
        offset: randomOffsetNumer,
        fields: '*,categories.category_id.*',
        limit: 1,
      };
      const activity = await fetchActivities(params);

      // eslint-disable-next-line prefer-destructuring
      randomActivity.value = activity[0];
    };

    const getActivities = async () => {
      const params = {
        fields: '*,categories.category_id.*',
        offset: currentPage.value * activitiesPerPage,
        limit: activitiesPerPage,
        sort: sortBy,
      };
      activities.value = await fetchActivities(params);
    };


    const scrollToListStart = (() => {
      const scrollTarget = allActivitiesList.value;
      if (scrollTarget) {
        scrollTarget.scrollIntoView({ behavior: 'smooth' });
      }
    });


    onMounted(() => {
      getNumberOfActivities().then(() => getSingleActivity());
      getActivities();
    });


    watch(currentPage, () => {
      getActivities();
    });


    const icons = {
      settings: mdiTuneVertical,
    };

    return {
      randomActivity,
      getSingleActivity, // functions returned behave the same as methods
      totalActivities,
      icons,
      drawer,
      toggleDrawer,
      numberOfPages,
      activities,
      currentPage,
      scrollToListStart,
      allActivitiesList,
    };
  },
});
</script>
