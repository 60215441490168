<template>
  <div class="v-markdown" v-html="html" />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { sanitize } from 'dompurify';
import marked from 'marked';

export default defineComponent({
  name: 'VMarkdown',
  components: {
    //
  },
  props: {
    modelValue: {
      type: String,
      required: true,
      default: '',
    },
  },

  setup(props) {
    const html = computed(() => {
      const htmlUnsafe = marked(props.modelValue);
      const htmlSanitized = sanitize(htmlUnsafe);

      return htmlSanitized;
    });

    return { html };
  },
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .v-markdown {
   
  }
</style>
