
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AlleAbendgestaltungen',
  components: {
    //
  },
  props: {
    //
  },
});
