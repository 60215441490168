<template>
  <div class="v-drawer">
    <div >
      <div class="overlay" :style="[opacity, transformation]" @click.stop="closeDrawer"/>
      <div class="v-drawer-content" :style="transformation" >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'VDrawer',
  components: {
    //
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const transformation = computed(() => {
      if (!props.modelValue) return 'transform: translateX(100%);';
      return 'transform: translateX(0);';
    });

    const opacity = computed(() => {
      if (!props.modelValue) return 'opacity: 0;';
      return 'opacity: 0.75;';
    });

    const closeDrawer = () => {
      emit('update:modelValue', !props.modelValue);
    };

    return { transformation, opacity, closeDrawer };
  },
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .v-drawer {
    top: 0;
    right: 0;
    // width: 100%;
    // height: 100%;
    position: fixed;

    .v-drawer-content {
      position: fixed;
      top: 0px;
      right: 0;
      width: 500px;
      max-width: 90%;
      height: 100%;
      background-color: var(--color-background);
      will-change: transform;
      transition: transform 0.5s;
      transform: translateX(100%);
    }
    

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-background-dark);
      will-change: opacity;
      transition: opacity 0.2s;
    }
  }

</style>
