import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import AlleAbendgestaltungen from '../views/AlleAbendgestaltungen.vue';
import ApiPage from '../views/ApiPage.vue';
import Error404 from '../views/Error_404.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    alias: ['/seite/1', '/page/1'],
    name: 'Start',
    component: Home,
  },
  {
    path: '/seite/:pageId?',
    alias: ['/page/:pageId?'],
    name: 'Page',
    component: Home,
  },
  {
    path: '/ueber',
    name: 'Über das Projekt',
    component: ApiPage,
    meta: {
      title: 'Über das Projekt',
      apiSlug: 'ueber-das-projekt',
    },
  },
  {
    path: '/alle-abendgestaltungen',
    name: 'Alle Abendgestaltungen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AlleAbendgestaltungen,
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: ApiPage,
    meta: {
      title: 'Impressum',
      apiSlug: 'impressum',
    },
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: ApiPage,
    meta: {
      title: 'Datenschutz',
      apiSlug: 'datenschutz',
    },
  },
  {
    path: '/404',
    name: '404',
    component: Error404,
  },
  // {
  //   path: '/custom',
  //   name: 'custom',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "legal" */ '../views/Page.vue'),
  // },
];

export default routes;
