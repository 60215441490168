
import { defineComponent, computed } from 'vue';
import useElevationClass, { elevationProps } from '@/composables/use-elevation-class/';

export default defineComponent({
  name: 'VCard',
  components: {
    //
  },
  props: {
    ...elevationProps,
  },
  setup(props) {
    const elevationClass = computed(() => {
      if (props.elevation) {
        return useElevationClass(props).value;
      }
      return useElevationClass({ elevation: 6 }).value;
    });

    return { elevationClass };
  },
});
