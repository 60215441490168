
import { defineComponent, PropType, ref } from 'vue';
import { getUniqueId } from '@/utils/helper';

interface VAutocompleteOptions {
  key: string | number,
  value: string | number,
}

export default defineComponent({
  name: 'VAutocomplete',
  components: {
    //
  },
  props: {
    options: {
      type: Array as PropType<Array<VAutocompleteOptions> | Array<number> | Array<string>>,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
  setup() {
    const id = getUniqueId('autocomplete-');
    const selection = ref<Array<string | number | VAutocompleteOptions>>([]);
    const input = ref<string>('');

    const updateSelection = (event: { target: HTMLInputElement }) => {
      if (event.target.value) {
        input.value = '';
        if (!selection.value.includes(event.target.value)) {
          selection.value.push(event.target.value);
        }
      }
    };

    const removeSelection = (selectionToRemove: string | number | VAutocompleteOptions) => {
      selection.value = selection.value.filter((value) => value !== selectionToRemove);
    };

    return {
      id, selection, updateSelection, input, removeSelection,
    };
    // const optionsC = computed((props) => {
    //   // TODO Add Reset Option if set via prop
    //   const computedOptions: Array<VAutocompleteOptions> = [];
    //   props.options.forEach((option: number | string | VAutocompleteOptions) => {
    //     if (props.addReset) {
    //       computedOptions.push({ key: '', value: '- - -' });
    //     }
    //     if (typeof option === 'string' || typeof option === 'number') {
    //       computedOptions.push({ key: option, value: toString });
    //     } else {
    //       computedOptions.push({ key: option.key, value: option.value });
    //     }
    //   });
    //
    //   return computedOptions;
    // });
    //
    // return { optionsC };
  },
});
