
import { defineComponent } from 'vue';
import { vButton } from '../v-button';

export default defineComponent({
  name: 'vPagination',
  components: {
    vButton,
  },
  props: {
    numberOfPages: {
      type: Number,
      reuired: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    // const onClick = (event: MouseEvent) => {
    const onClick = (event: MouseEvent) => {
      emit('click', event);
    };

    return { onClick };
  },
});
