<template>
  <div class="v-pagination" v-if="numberOfPages">
    <template
      v-for="page in numberOfPages"
      :key="page"
    >
      <v-button
        :outlined="page === currentPage ? false : true"
        :to="{ name: 'Page', params: {pageId: page}}"
        @click.stop="onClick"
      >
        {{ page }}
      </v-button>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { vButton } from '../v-button';

export default defineComponent({
  name: 'vPagination',
  components: {
    vButton,
  },
  props: {
    numberOfPages: {
      type: Number,
      reuired: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    // const onClick = (event: MouseEvent) => {
    const onClick = (event: MouseEvent) => {
      emit('click', event);
    };

    return { onClick };
  },
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .v-pagination {
    @include flex-row;
    justify-content: center;

    .v-button {
      margin-right: 10px;
    }
  }
</style>
