import { Ref } from 'vue';

export type SizeType = {
  'height': number,
  'width': number,
};


export default function getSize(htmlElement: Ref<HTMLElement | undefined >): SizeType {
  const height = htmlElement.value?.clientHeight || 0;
  const width = htmlElement.value?.clientWidth || 0;
  return { height, width };
}
