<template>
  <div class="v-button">
    <component
      :is="componentType"
      :to="to"
    >
      <div class="button" :class="[
        {
          fab,
          outlined,
          bottom,
          top,
          right,
          left,
          fixed,
        },
        elevationClass,
      ]">
        <div class="button-content">
          <slot />
        </div>
      </div>
    </component>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import useElevationClass, { elevationProps } from '@/composables/use-elevation-class/';

export default defineComponent({
  name: 'VButton',
  components: {
    //
  },
  props: {
    fab: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    // bottom, top, right, left only to be used in combination with "fab"
    // as they only work on absolute
    bottom: {
      type: Boolean,
      required: false,
    },
    top: {
      type: Boolean,
      required: false,
    },
    right: {
      type: Boolean,
      required: false,
    },
    left: {
      type: Boolean,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: false,
    },
    to: {
      type: Object as PropType<string | Location>,
      required: false,
    },
    ...elevationProps,
  },
  setup(props) {
    const elevationClass = computed(() => {
      if (props.elevation) {
        return useElevationClass(props).value;
      }
      if (props.fab) {
        return useElevationClass({ elevation: 12 }).value;
      }
      return null;
    });

    // eslint-disable-next-line arrow-body-style
    const componentType = computed(() => {
      return props.to ? 'router-link' : 'div';
    });

    return { elevationClass, componentType };
  },
});
</script>

<style>
  :root {
    --v-button-height: 40px;
    --v-button-width: auto;
  }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @include elevation;

  .v-button {
    display: inline-flex;
    align-items: center;

    a {
      text-decoration: none;
    }


    .button {
      align-items: center;
      background-color: var(--color-main);
      color: var(--color-text--light);
      padding: 5px 19px;
      border-radius: 10px;
      height: var(--v-button-height);
      width: var(--v-button-width);

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      &:hover {
        cursor: grab;
        filter: brightness(95%);
      }

      &.fab {
        width: var(--v-button-height);
        padding: 5px;
        border-radius: 50%;
        position: absolute;
        z-index: 5;

        &.bottom {
          bottom: 1vh;
        }
        &.top {
          top: 1vh;
        }
        &.right {
          right: 1vw;
        }
        &.left {
          left: 1vw;
        }
      }

      &.fixed {
        position: fixed;
      }

      &.outlined {
        background-color: var(--color-background);
        border: 1px solid var(--color-main);
        color: var(--color-main);
      }

    }
  }

</style>
