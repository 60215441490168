import { computed, ComputedRef } from 'vue';

export const elevationProps = {
  elevation: {
    type: Number,
    default: 0,
  },
};

interface RequiredProps {
  elevation: number;
}

export default function useElevationClass<T>(props: T & RequiredProps): ComputedRef<string | null> {
  const elevationClass = computed<string | null>(() => {
    if (props.elevation) return `elevation-${props.elevation}`;
    return 'elevation-0';
  });

  return elevationClass;
}
