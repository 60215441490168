
<template>
  <div class="error-404">
    <h1>404</h1>
  </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Error404',

  setup() {
    //
  },
});
</script>

<style lang="scss" scoped>

</style>
